@import '~bootstrap/scss/bootstrap';

body {
  font-family: "HelveticaRegular", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased; /* For WebKit-based browsers (Chrome, Safari) */
  -moz-osx-font-smoothing: grayscale; /* For Firefox on macOS */
}

html, body {
  width: 100%;
  height: 100%;
}

