@use "./fonts.scss";
@use "./colors.scss" as tokens;

.search-container {
    position: relative;
    min-width: 100%;
  
    :focus {
        outline: none;
        box-shadow: 0 0 2px 2px rgb(192, 192, 193); /* Adjust the blur radius and color as needed */
    }
    // .search-input-field{
    //   width: 80%;
    // }
}
.p-left{
  padding-left: 1rem;
}
.center-aligned-header .ag-header-cell-label {
    justify-content: center;
    text-align: center;
  }

  .truncate-tooltip {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%; /* adjust as per your requirement */
    position: relative;
  }

// .search-container {
//     @media (min-width:995px) {
//       min-width: 360px !important;
//     }
//   }
  @media (min-width:995px) {
    .search-container{
      min-width: 360px !important;
    }
  }
  @media (min-width:576px) {
    .search-container{
      &.w-280{
        min-width: 17.5rem !important;
      }
    }    
  }
  @media (max-width:576px) {
    .p-left{
      padding-left: 0px !important;
    }
  }
  @media (min-width: 990px) and (max-width: 1120px) {
    .search-container{
      &.w-280{
        min-width: 13rem !important;
        
      }
    }
    .p-left{
      padding-left: 0.5rem !important;
    }
  }
  .grid-center-aligned-text {
    text-align: center;

    input[type="radio"] {
      width: 18px;
      height: 38px;
      accent-color: tokens.$radio-button-share-bank-detail;
      cursor: pointer;
    }
  }
input[type=text] {
    height: 40px;
    padding-left: 35px;
    border: 1px solid tokens.$ag-grid-search-filter-border-bg;
    border-radius: 4px;
    font-family: "HelveticaRegular", "Arial", sans-serif;
    font-size: 16px;

    ::placeholder {
        color: tokens.$ag-grid-text;
    }
}

#search-firm-input-field {
    width: 100%;
}

.search-icon {
    position: absolute;
    padding: 4px 0 0 9px;
    font-size: 20px;
}
.clear-search-button {
  position: absolute;
  right: 7%;
  top: 6px;
  cursor: pointer;
  z-index: 1;
}
