.custom-access-denied-container {
    background-color: rgba(241, 242, 243, 1);
    .access-denied-text {
        color: rgba(0, 0, 0, 1);
        font-weight: 400;
        font-size: 16px;
        line-height: 1;
        &.font-60 {
            font-size: 60px;
            line-height: 1;
        }
    }
}