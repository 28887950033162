@use "./fonts.scss";
@use "./colors.scss" as tokens;

.attorney-client-title-page-container {
  background-color: rgba(251, 251, 251, 255);
  padding-top: 64px;

  .attoner-client-title {
    background-color: tokens.$tooltip-body;
    border-bottom: 1px solid tokens.$page-sub-title-bar-border;
    text-transform: capitalize;

    .client-title-bar-buttons {
      .dropdown {
        button {
          font-size: 1.25rem;
          font-family: "HelveticaRegular", "Arial", sans-serif;
          border-radius: 0.25rem;
          background-color: tokens.$form-button-blue;
          height: 2.5rem;
          border: 1px solid tokens.$form-button-blue;
          padding: 2px 16px 4px 16px;
          span {
            font-size: 1rem;
            padding-right: 0 !important;
          }
        }

        .dropdown-menu.show {
          // background-color: red;
          .dropdown-item {
            width: 12.5rem;
            height: 2.5rem;
            padding: 10px, 12px, 10px, 16px;
            // border-radius: 2px;
            border: 0.01rem solid rgb(181, 187, 204);
            font-size: 14px;
            font-weight: 400;
            color: rgba(0, 0, 0, 1);
            padding: 8px 0;
          }
        }
      }
    }
  }

  .page-title {
    font-size: 20px;
    font-weight: 700;
  }
}

.attorney-folders {
  .noof-document-uploaded {
    font-size: 16px;
    font-weight: 400;

    .no-uploaded {
      font-weight: 700;
    }
  }
}

.folder-container-new {
  .big-folder {
    width: 349px;
    height: 295px;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;

    &.color-template-1 {
      background-image: url("../assets/images/folder-image-1.png");
      &:hover {
        background-image: url("../assets/images/color-1-hover.png");
      }
    }

    .folder-name {
      font-size: 18px;
      font-weight: 700;
      color: #ffffff;
    }

    .document-count {
      font-size: 98px;
      font-weight: 700;
      color: #ffffff;
    }

    .edited-date {
      bottom: 20px;
      font-size: 16px;
      color: #ffffff;
      font-weight: 400;
    }

    .three-dots {
      right: 10px;
      top: 40px;
    }
  }

  .other-common-folder-new {
    .common-folders-new {
      width: 218px;
      height: 140px;
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;
      &.color-template-1 {
        background-image: url("../assets/images/folder-image-11.png");
        &:hover {
          background-image: url("../assets/images/color-11-hover.png");
        }
      }
      &.color-template-2 {
        background-image: url("../assets/images/folder-image-2.png");
        &:hover {
          background-image: url("../assets/images/color-2-hover.png");
        }
      }

      &.color-template-3 {
        background-image: url("../assets/images/folder-image-3.png");
        &:hover {
          background-image: url("../assets/images/color-3-hover.png");
        }
      }

      &.color-template-4 {
        background-image: url("../assets/images/folder-image-4.png");
        &:hover {
          background-image: url("../assets/images/color-4-hover.png");
        }
      }

      &.color-template-5 {
        background-image: url("../assets/images/folder-image-5.png");
        &:hover {
          background-image: url("../assets/images/color-5-hover.png");
        }
      }

      &.color-template-6 {
        background-image: url("../assets/images/folder-image-6.png");
        &:hover {
          background-image: url("../assets/images/color-6-hover.png");
        }
      }

      &.color-template-7 {
        background-image: url("../assets/images/folder-image-7.png");
        &:hover {
          background-image: url("../assets/images/color-7-hover.png");
        }
      }

      &.color-template-8 {
        background-image: url("../assets/images/folder-image-8.png");
        &:hover {
          background-image: url("../assets/images/color-8-hover.png");
        }
      }

      &.color-template-9 {
        background-image: url("../assets/images/folder-image-9.png");
        &:hover {
          background-image: url("../assets/images/color-9-hover.png");
        }
      }

      &.color-template-10 {
        background-image: url("../assets/images/folder-image-10.png");
        &:hover {
          background-image: url("../assets/images/color-10-hover.png");
        }
      }

      .folder-name {
        font-size: 16px;
        margin-top: 26px;
        width: 85%;
        font-weight: 700;
        color: #fff;
        line-height: 1.25;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .document-count {
        font-size: 44px;
        margin-top: 10px;
        font-weight: 700;
        color: #fff;
        line-height: 1;
      }

      .edited-date {
        bottom: 5px;
        font-size: 12px;
        color: #fff;
        font-weight: 400;
      }

      .three-dots {
        right: 10px;
        top: 22px;
      }
      .new-folder-actions {
        width: 125px;
        background-color: rgba(255, 255, 255, 1);
        border-radius: 4px;
        position: absolute;
        top: 20px;
        left: 178px;
        border: 1px solid rgba(217, 217, 217, 1);
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
        z-index: 999;
        .action-text {
          font-size: 14px;
          font-weight: 400;
        }
        div {
          padding: 5px 12px;
        }
      }
    }
  }

  .big-folder-small-devices {
    width: 100%;
    height: 260px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;

    &.color-template-1 {
      background-image: url("../assets/images/folder-image-1.png");
      &:hover {
        background-image: url("../assets/images/color-1-hover.png");
      }
    }

    .folder-name {
      font-size: 18px;
      font-weight: 700;
      color: #fff;
    }

    .document-count {
      font-size: 98px;
      font-weight: 700;
      color: #fff;
    }

    .edited-date {
      bottom: 20px;
      font-size: 16px;
      color: #fff;
      font-weight: 400;
    }

    .three-dots {
      right: 8px;
      top: 40px;
    }
  }

  .common-folders-new-small-devices {
    width: 100%;
    height: 126px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;

    &.color-template-1 {
      background-image: url("../assets/images/folder-image-11.png");
      &:hover {
        background-image: url("../assets/images/color-11-hover.png");
      }
    }
    &.color-template-2 {
      background-image: url("../assets/images/folder-image-2.png");
      &:hover {
        background-image: url("../assets/images/color-2-hover.png");
      }
    }

    &.color-template-3 {
      background-image: url("../assets/images/folder-image-3.png");
      &:hover {
        background-image: url("../assets/images/color-3-hover.png");
      }
    }

    &.color-template-4 {
      background-image: url("../assets/images/folder-image-4.png");
      &:hover {
        background-image: url("../assets/images/color-4-hover.png");
      }
    }

    &.color-template-5 {
      background-image: url("../assets/images/folder-image-5.png");
      &:hover {
        background-image: url("../assets/images/color-5-hover.png");
      }
    }

    &.color-template-6 {
      background-image: url("../assets/images/folder-image-6.png");
      &:hover {
        background-image: url("../assets/images/color-6-hover.png");
      }
    }

    &.color-template-7 {
      background-image: url("../assets/images/folder-image-7.png");
      &:hover {
        background-image: url("../assets/images/color-7-hover.png");
      }
    }

    &.color-template-8 {
      background-image: url("../assets/images/folder-image-8.png");
      &:hover {
        background-image: url("../assets/images/color-8-hover.png");
      }
    }

    &.color-template-9 {
      background-image: url("../assets/images/folder-image-9.png");
      &:hover {
        background-image: url("../assets/images/color-9-hover.png");
      }
    }

    &.color-template-10 {
      background-image: url("../assets/images/folder-image-10.png");
      &:hover {
        background-image: url("../assets/images/color-10-hover.png");
      }
    }

    .folder-name {
      width: 85%;
      font-size: 14px;
      font-weight: 700;
      padding-top: 25px;
      color: #fff;
      line-height: 1;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      &.mb-height {
        height: 7rem;
      }
    }

    .document-count {
      font-size: 12px;
      font-weight: 400;
      color: #fff;
      line-height: 1;
    }

    .edited-date {
      bottom: 5px;
      font-size: 12px;
      color: #fff;
      font-weight: 400;
    }

    .three-dots {
      right: 5px;
      top: 20px;
    }
    .new-folder-actions {
      height: 66px;
      width: 112px;
      background-color: rgba(255, 255, 255, 1);
      border-radius: 4px;
      position: absolute;
      top: 20px;
      left: 58px;
      border: 1px solid rgba(217, 217, 217, 1);
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
      z-index: 999;
      .action-text {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}

.upload-client-container {
  .upload-doc-subtitle {
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
  }
  .update-info-warning-box {
    border-radius: 8px;
    color: rgba(0, 0, 0, 1);
    background: rgb(251, 212, 210);
    font-size: 16px;
    width: 100%;
    height: fit-content;
    .info-icon-addfamily {
      width: auto;
      padding-top: 0;
    }
    .update-info-text {
      margin-top: 0;
    }
    .close-button-ripon {
      cursor: pointer;
      width: 24px !important;
      height: 24px !important;
      padding: 0;
    }
  }

  .document-type-container {
    width: 40%;

    input[type="file"] {
      display: none;
    }

    .radio-buttons-label {
      font-size: 16px;
      font-weight: 400;
    }

    .form-check-input {
      border: 2px solid #000000;
    }

    .form-check-input:checked {
      background-color: rgba(235, 88, 22, 255);
      border: rgba(235, 88, 22, 255);
    }
  }

  .document-list-container {
    .custom-card {
      color: rgba(22, 26, 73, 1);
      border-radius: 4px;
    }

    .custom-card-header {
      background-color: rgba(232, 232, 237, 1);
      color: rgba(22, 26, 73, 1);
      font-size: 16px;
      font-weight: 700;
      border-radius: 0;
      border: none;
      cursor: pointer;
      .custom-folder-name {
        padding-left: 1.125rem;
      }
    }

    .doc-section {
      .file-name-others {
        width: fit-content;
      }

      .delete-doc-others {
        text-decoration: underline;
        cursor: pointer;

        &.small {
          position: absolute;
          top: 0;
          right: 0;
        }

        &.large {
          position: relative;
          top: -3px;
        }
      }

      .icon-document-web-section {
        & > div {
          svg {
            cursor: pointer;
          }
        }
      }

      .table-custom-document {
        margin: 0;

        thead {
          tr {
            td {
              color: rgba(22, 26, 73, 1);
              font-size: 16px;
              font-weight: 700;
              padding: 5px 8px;
            }
          }
        }

        tbody {
          tr {
            vertical-align: bottom;

            td {
              color: rgba(51, 51, 51, 1);
              font-size: 16px;
              font-weight: 400;
              padding: 5px 8px;

              &:last-child {
                vertical-align: bottom;
              }
            }
          }
        }

        tr {
          td {
            border: 0;
          }
        }
      }

      .doc-inner-section > div {
        div {
          &:first-child {
            color: rgba(22, 26, 73, 1);
            font-size: 16px;
            font-weight: 700;
          }

          &:last-child {
            color: rgba(51, 51, 51, 1);
            font-size: 16px;
            font-weight: 400;
          }
        }
      }

      .file-part {
        text-decoration: underline;
        cursor: pointer;
        width: fit-content;
      }
    }

    .people-section {
      font-size: 16px;
      font-weight: 400;
      color: rgba(22, 26, 73, 1);

      .underline {
        font-weight: 700;
        text-decoration: underline;
        cursor: pointer;
      }

      .people-details-section {
        div {
          &:first-child {
            color: rgba(22, 26, 73, 1);
            font-size: 16px;
            font-weight: 700;
          }
        }

        .people-details-inner-section {
          // padding-right: 30%;
          width: 50%;

          & > div {
            div {
              &:first-child {
                color: rgba(22, 26, 73, 1);
                font-size: 16px;
                font-weight: 700;
              }

              &:last-child {
                color: rgba(51, 51, 51, 1);
                font-size: 16px;
                font-weight: 400;
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}

.add-people-container {
  height: unset !important;

  .stepper-container {
    width: 60%;

    .skip-icon-container {
      padding-bottom: 1.2rem;
      position: relative;

      .circle-icon {
        position: absolute;
        right: -0.65rem;
        top: -3px;
      }

      .line-icon {
        position: absolute;
        bottom: -0.125rem;
        right: -0.34rem;

        .icon-style {
          height: 10px;
        }
      }
    }
  }

  .add-people-form {
    width: 90%;

    .executor-form {
      .divider {
        left: -2%;
        right: -13%;
        //width: 100%;
        //height: 0.02rem;
        border-bottom: 1px solid rgba(208, 211, 214, 1);

        //background-color: rgba(208, 211, 214, 1);
        .delete-icon {
          cursor: pointer;
        }

        .label-text {
          font-size: 20px;
          font-weight: 700;
        }
      }
    }
  }
}

.add-successor {
  width: 90%;
}

.custom-toast-video-sigining {
  border-left: 4px solid green;
  border-radius: 0 !important;

  .notification {
    font-size: 15px;
    font-weight: 700;
    color: rgba(51, 51, 51, 1);
  }

  .upload {
    font-size: 15px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
  }
}

@media only screen and (max-width: 1024px) and (min-width: 600px) {
  .attorney-folders {
    .folders-container {
      padding: 0 !important;

      .folder {
        width: 96% !important;
      }
    }
  }

  .upload-client-container {
    .document-type-container {
      width: 70%;
    }
  }

  .add-successor {
    padding: 0 1.5rem;
  }
}

@media (max-width: 576px) {
  .upload-client-container {
    .document-type-container {
      width: 100%;

      .radio-buttons-label {
        text-align: center;
      }

      .browse-upload-button {
        display: flex;
        justify-content: center;
        // padding-bottom: 1rem;
      }
    }
  }

  .add-people-container {
    .stepper-container {
      width: 100%;
    }

    .add-people-form {
      width: 100%;

      .executor-form {
        .divider {
          left: 0;
          right: 0;
          border-bottom: 1px solid rgba(208, 211, 214, 1);

          .delete-icon {
            cursor: pointer;
          }

          .label-text {
            font-size: 20px;
            font-weight: 700;
          }
        }
      }
    }
  }

  .add-successor {
    width: 100%;
  }

  .attorney-client-title-page-container {
    .page-title {
      display: flex;
      text-align: -webkit-center;
    }

    .attoner-client-title {
      .client-title-bar-buttons {
        margin-top: 1rem;

        .dropdown {
          margin: auto;

          button {
            width: 12.1875rem;
          }
        }

        .pd-mb {
          margin-bottom: 0.375rem;
        }
      }
    }
  }
  .mb-ps {
    padding-left: 1.5rem;
  }
}

@media (max-width: 1024px) {
  .document-list-container {
    padding-top: 10px;
  }

  .doc-inner-section {
    display: flex;
    flex-direction: column;
    padding-right: 0 !important;

    &.multiple {
      & > div {
        padding-bottom: 5px;
      }
    }

    & > div {
      padding-bottom: 15px;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  .people-details-inner-section {
    display: flex;
    flex-direction: column;
    padding-right: 0 !important;

    & > div {
      padding-bottom: 15px;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

@media (min-width: 1028px) {
  .add-people-form {
    .executor-form {
      .pd-left {
        padding-left: 1.2rem !important;
      }
    }
  }
}

@media (min-width: 1400px) {
  .doc-inner-section {
    padding-right: 10%;
  }
}

.all-folders {
  margin-top: 1.89rem;
}
