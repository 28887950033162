@use "./fonts.scss";
@use "./colors.scss" as tokens;

.tab-container-ag-grid{
  min-width: 100%;
 }

.ag-tab-button {
  @media (min-width:995px) {
    width: 420px !important;
  }
}
.ag-tab-button {
  font-family: "HelveticaRegular", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 600;

  @media screen and (max-width:750px) {
    font-size: 16px;
    text-align: center;

      #firmTabs-tab-attorneyBased {
        height: 100%;
        white-space: normal;
        font-size: 17px;
      }
      #firmTabs-tab-nonAttorneyBased {
        height: 100%;
        white-space: normal;
        font-size: 17px;
      }
    }
  
    .nav-pills {
      display: flex;
      padding: 0;
      flex-wrap: nowrap;
      min-height: 40px;
      text-align: center;
    }
  
    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
      background-color: tokens.$ag-grid-tab-bg;
      color: tokens.$ag-grid-tab-text;
      border: 1px solid transparent; 
      font-weight: 600;
      flex: 1;
    }

  #firmTabs-tab-attorneyBased {
    border-radius: 4px 0 0 4px !important;
  }

  #firmTabs-tab-nonAttorneyBased {
    border-radius: 0 4px 4px 0 !important;
  }

  .nav-pills .nav-link {
    background-color: transparent;
    color: tokens.$ag-grid-tab-bg;
    border: 1px solid tokens.$ag-grid-tab-bg;
    font-weight: 600;
    white-space: nowrap;
    flex: 1;
  }
}


  