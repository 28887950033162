@use "./fonts.scss";
@use "./colors.scss" as tokens;

.content {
    opacity: 0;
    max-height: 0;
    transition: opacity 0.3s ease, max-height 0.3s ease;

    &.open {
        opacity: 1;
        max-height: 300px;
    }
}

.btn-onboarding {
    z-index: 9999;
    width: 249px;
    &.zindex-0{
        z-index: 0 !important;
    }
}
.btn-onboarding:disabled{
    background-color: #d6d6d6 !important; 
    color: #a1a1a1 !important; 
    cursor: not-allowed; 
    border: 1px solid #cccccc !important;
    opacity: 1 !important;
}

.super-admin-section {
    .super-admin-title {
        font-size: 1.125rem;
        color: tokens.$form-title-full;
        font-weight: 400;
    }
}

.super-admin-divider::after {
    content: "";
    display: block;
    width: 90vw;
    border-bottom: 1px solid #D0D3D6;
    margin-top: 4px;
}

.firm-onboarding-page {
    background-color: tokens.$page-sub-title-bar-bg;
}

.firm-onboard-container {
    max-width: 100%;

    .form-row-div {
        background-color: #fff;

        .form-row {
            width: 78%;
        }
    }

}

@media (max-width: 1200px) {
    .content {
        opacity: 0;
        max-height: 0;
        transition: opacity 0.3s ease, max-height 0.3s ease;

        &.open {
            opacity: 1;
            max-height: 400px;
        }
    }
}

.select-placeholder optgroup,
.select-placeholder option {
    font-family: "HelveticaRegular", "Arial", sans-serif;
    font-style: normal;
}

@media (max-width: 576px) {
    .firm-onboarding-page {
        .xs-space {
            padding-left: 1.5rem;
        }
    }

    .firm-onboard-container {
        padding-left: 0 !important;
        padding-right: 0 !important;

        .form-row-div {
            padding-left: 1.5rem;

            .form-row {
                width: 100%;
            }
        }
    }

    .super-admin-divider::after {
        width: 100%;
    }
}

@media only screen and (max-width: 960px) and (min-width: 600px) {
    .firm-onboard-container {
        .form-row-div {
            .form-row {
                width: 86%;
            }
        }
    }
}

@media only screen and (max-width: 960px) and (min-width: 600px) {
    .super-admin-divider::after {
        width: 90vw;
    }
}

@media only screen and (max-width: 1200px) and (min-width: 960px) {
    .super-admin-divider::after {
        width: 90vw;
    }
}

