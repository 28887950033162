@use "./fonts.scss";
@use "./colors.scss" as tokens;

.firm-reports-page-container {
  background-color: rgba(250, 250, 250, 0.8);

  .firm-report-nav-div {
    background-color: tokens.$form-page-container-bg;
    font-size: 14px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 768px) {
    .firm-report-nav-div {
      background-color: tokens.$form-page-container-bg;
      font-size: 16px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

      .nav {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
      }
      .nav-item {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-around;
      }
      .nav-link {
        width: 100%;
      }
    }
  }

  .nav-link {
    color: black;
    &.active,
    &:hover {
      color: black;
      border-bottom: 3px solid black;
    }
  }
}

.firm-reports-table-title {
  font-size: 16px;
  font-weight: 700;
  color: rgba(81, 88, 105, 1);
  text-wrap: nowrap;
}
.firm-reports-second-div {
  background-color: rgb(255, 255, 255);

  @media (max-width: 768px) {
    #search-firm-input-field {
      width: 100%; /* Adjust width for medium screens */
    }
  }

  .clear-search-button-mb {
    position: absolute;
    right: 7%;
    top: 16px;
    cursor: pointer;
    z-index: 1;
  }
  .bg-blue {
    background-color: rgba(187, 227, 231, 1);
  }
  .bg-grey {
    background-color: rgba(208, 211, 214, 1);
  }
  .bg-red {
    background-color: rgba(243, 112, 104, 0.3);
  }
  .bg-green {
    background-color: rgba(203, 234, 201, 1);
  }
  .bg-purple {
    background-color: rgba(225, 215, 229, 1);
  }
  .firm-reports-tiles {
    width: 175px;
    height: 50px;
  }

  .firm-report-tiles {
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

    .firm-titles-header {
      color: rgba(81, 88, 105, 1);
      font-size: 16px;
      font-weight: 400;
      text-wrap: nowrap;
    }

    .firm-titles-value {
      color: rgba(43, 51, 72, 1);
      font-size: 24px;
      font-weight: 700;
    }
  }

  .firm-report-tiles-2 {
    border-radius: 4px;
    transition: background-color 0.3s ease;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

    .firm-titles-header {
      color: rgba(81, 88, 105, 1);
      font-size: 16px;
      font-weight: 400;
    }

    .firm-titles-value {
      color: rgba(43, 51, 72, 1);
      font-size: 24px;
      font-weight: 700;
    }
  }
  .selected-tile-blue {
    border: 1px solid rgba(36, 118, 126, 1); /* Add border to selected tile */
  }
  .selected-tile-grey {
    border: 1px solid rgba(114, 114, 114, 1); /* Add border to selected tile */
  }
  .selected-tile-red {
    border: 1px solid rgba(255, 81, 72, 1); /* Add border to selected tile */
  }
  .selected-tile-green {
    border: 1px solid rgba(14, 129, 7, 1); /* Add border to selected tile */
  }
}

.dropdown-menu.show {
  display: block;
  position: absolute !important;
  inset: 0px 0px auto auto !important;
  transform: translate3d(0px, 27.2px, 0px);
}
.dropdown-toggle::after {
  display: none !important;
}
.dropdown-item:active {
  background-color: transparent !important;
  color: black !important;
}

.dropdown-menu {
  padding: 0 !important;
}
.dropdown-item:hover {
  background-color: rgba(235, 88, 22, 0.12) !important;
}

.cursor-pointer {
  cursor: pointer;
}

.share-doc-modal-report-header {
  border-bottom: 0 !important;
  .modal-title {
    font-size: 16px !important;
    font-weight: 700 !important;
  }
}
