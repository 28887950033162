.register-modal {
  &.register-modal-vet-login {
    #modal-popup-container-search {
      width: 440px;
      border-radius: 8px;

      .modal-content {
        .modal-body {
          padding-bottom: 0px !important;
          .modal-content-container {
            padding-bottom: 6px !important;
          }
        }
      }
    }
  }
  #modal-popup-container-search {
    width: 530px;
    border-radius: 8px;

    .modal-content {
      .modal-body {
        padding-bottom: 0px !important;
        .modal-content-container {
          padding-bottom: 6px !important;
        }
      }
    }
  }

  .result-subtext-register {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 1);
    text-align: center;
  }
  .modal-content-register {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: rgba(0, 0, 0, 1)
  }

  .result-subtext-register-login {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 1);
    text-align: center;
  }

  .result-subtext-register-fail {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 1);
    text-align: center;
  }
  .modal-content-register-fail {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0%;
    text-align: center;
    color: #333333;
  }
  .line {
    border-top: 4px solid rgba(80, 184, 74, 1); /* Green line */
    width: 65px;
    top: 433px;
    left: 608px;
    justify-self: center;
  }
  .modal-button-section {
    font-size: 16px;
    font-weight: 600;

    .btn-yes {
      background-color: #161a49;
      color: #ffffff;
      width: 81px;
      &:hover {
        background-color: #161a49;
        color: #ffffff;
      }
    }
    .btn-no {
      background-color: #ffffff;
      border: 1px solid #161a49;
      color: #161a49;
      width: 81px;
      &:hover {
        background-color: #ffffff;
      }
    }
  }
}

@media (max-width: 568px) {
  .register-modal {
    &.register-modal-vet-login {
      #modal-popup-container-search {
        width: 96%;
      }
    }
    #modal-popup-container-search {
      width: 96%;
    }
  }
}
