@use "./colors.scss" as tokens;
.death-claim-title-page-container {
  padding-top: 64px;
  border-bottom: 1px solid tokens.$page-sub-title-bar-border;
  .death-claim-title {
    background-color: tokens.$tooltip-body;
    text-transform: capitalize;
  }
  .page-title {
    font-size: 20px;
    font-weight: 1000;
  }
}
.info-blue{
  width: 13px !important;
  height: 13px !important;
}
.deathclaim-question-container {
  background-color: tokens.$page-sub-title-bar-bg;
  .question-container {
    display: contents;
  }
  .button-container {
    display: inline-block;
  }
}
.deathclaim-message-container {
  background: #F6F6F6;
  margin-right: 100px;
  width: 100%;
  position: relative;
  z-index: 5 !important;
  .question-container {
    display: contents;
    font-family: "HelveticaRegular", "Arial", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 25.2px;
    text-align: left;
    padding-right: 8px;
  }
}
.death-certificate {
  display: block;
}
.custom-button {
  &.btn-standard-deathclaim {
    font-size: 1rem;
    font-family: "HelveticaRegular", "Arial", sans-serif;
    border-radius: 0.25rem;
    width: 57px;
    background-color: tokens.$form-button-blue;
    padding: 0.313rem 1rem 0.25rem 1rem;
    height: 2.625rem;
    border: 1px solid tokens.$form-button-blue;
    &.ok-button {
      font-size: 16px;
      font-weight: 400;
    }
    &:hover {
      background-color: tokens.$form-button-blue-hover;
    }

    &:active {
      background-color: tokens.$form-button-blue-hover;
      box-shadow: none;

      &:focus {
        background-color: tokens.$form-button-blue-hover;
        box-shadow: none;
      }
    }

    &:focus {
      background-color: tokens.$form-button-blue-hover;
      box-shadow: none;
    }
  }

  &.btn-nobg-deathclaim {
    font-size: 1rem;
    font-weight: 700;
    width: 57px;
    background-color: tokens.$white-color;
    color: tokens.$font-blue-new;
    border: 1px solid tokens.$font-blue-new;
    padding: 0.313rem 1rem 0.25rem 1rem;
    height: 2.625rem;
    border-radius: 4px;

    //padding:5px 16px
    &:hover {
      background-color: tokens.$form-button-blue-hover;
      border: 1px solid tokens.$form-button-blue;
    }

    &:active {
      background-color: tokens.$form-button-blue-hover;
      box-shadow: none;

      &:focus {
        background-color: tokens.$form-button-blue-hover;
        box-shadow: none;
      }
    }
  }
  &.btn-standard-upload {
    font-size: 1rem;
    font-family: "HelveticaRegular", "Arial", sans-serif;
    border-radius: 0.25rem;
    background-color: tokens.$form-button-blue;
    padding: 0.313rem 1rem 0.25rem 1rem;
    height: 40px;
    border: 1px solid tokens.$form-button-blue;
    &:hover {
      background-color: tokens.$form-button-blue-hover;
    }

    &:active {
      background-color: tokens.$form-button-blue-hover;
      box-shadow: none;

      &:focus {
        background-color: tokens.$form-button-blue-hover;
        box-shadow: none;
      }
    }

    &:focus {
      background-color: tokens.$form-button-blue-hover;
      box-shadow: none;
    }
  }
}
.border {
  border-radius: 4px !important;
}
@media (max-width: 576px) {
  .deathclaim-question-container {
    justify-content: center;
    background-color: white;
    &.border {
      border: none !important;
    }
    .question-container {
      display: block;
    }
    .button-container {
      display: flex;
      margin: 10px 10px 55px 20px;
    }
  }
  .border {
    border: none !important;
  }
  .death-certificate-input {
    width: 100%;
  }
  .upload-block {
    text-align: center;
  }

.deathclaim-message-container {
  display:flex;

  .question-container {
    display: flex;
    margin-left: 25px;
  }
}
}