@use "./fonts.scss";
@use "./colors.scss" as tokens;

.firm-title-page-container {
  background-color: tokens.$page-sub-title-bar-bg;
  border-bottom: 1px solid tokens.$page-sub-title-bar-border;
  padding-top: 64px;
  .dropdown-toggle-wrapper {
    color: white;
  }
}

.firm-page-outer-container {
  background-color: tokens.$page-sub-title-bar-bg;

  .firm-page-container {
    height: calc(100vh - 121px);
    background-color: tokens.$form-page-container-bg;
    .close-button-ripon {
      width: 7px !important;
      height: 7px !important;
      padding: 8px;
      color: black !important;
    }
    .update-info-box {
      margin-top: 10px;
      padding: 8px;
      border: 0px;
      border-radius: 4px;
      background: rgba(251, 212, 210, 1);
      text-align: center;
      width: fit-content;
      position: absolute;
      right: 4%;
    }

    .update-info-text {
      background: transparent;
      color: black;
      font-family: "HelveticaRegular", "Arial", sans-serif;
      margin-top: 3px;
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      vertical-align: text-top;
    }

    .update-click-here {
      font-family: "HelveticaRegular", "Arial", sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      background: none;
      border: none;
      padding: 0;
      margin: 0;
      color: black;
      text-decoration: none;
      cursor: pointer;
    }
    .update-click-here:hover,
    .update-click-here:focus {
      color: black; /* Match link hover/focus color */
      text-decoration: underline; /* Remove underline on hover/focus if desired */
    }
    .info-icon-addfamily {
      width: 24px;
      height: 24px;
    }
    .firm-view-first {
      width: 35%;
    }

    .firm-view-label {
      font-family: "HelveticaRegular", "Arial", sans-serif;
      font-size: 0.875rem;
      font-weight: 700;
      color: rgba(51, 51, 51, 1);
    }

    .firm-view-content {
      font-family: "HelveticaRegular", "Arial", sans-serif;
      font-size: 1rem;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      &.email-id {
        position: absolute !important;
      }
    }

    .divider-firm {
      width: 100%;
      // height: 0.02rem;
      // background-color: rgba(208, 211, 214, 1);
    }

    .firm-super-admin-section {
      .firm-super-admin-title {
        color: rgba(51, 51, 51, 1);
        font-family: "HelveticaRegular", "Arial", sans-serif;
        font-size: 1.125rem;
        font-weight: 600;
      }

      .regenerate-password-icon {
        width: 25px;
        height: 25px;
        cursor: pointer;
      }

      .custom-table-firm-superadmin {
        thead {
          tr {
            th {
              color: rgba(51, 51, 51, 1);
              font-family: "HelveticaRegular", "Arial", sans-serif;
              font-size: 1rem;
              font-weight: 600;
              background-color: rgba(232, 232, 237, 1);
              border: none;

              &:first-child {
                border-top-left-radius: 0.25rem;
                text-align: center;
              }

              &:last-child {
                border-top-right-radius: 0.25rem;
                text-align: center;
              }
            }
          }
        }

        tbody {
          tr {
            td {
              color: rgba(51, 51, 51, 1);
              font-family: "HelveticaRegular", "Arial", sans-serif;
              font-size: 1rem;
              font-weight: 400;
              background-color: rgba(250, 250, 250, 1);
              border: none;

              &:first-child {
                border-bottom-left-radius: 0.25rem;
                text-align: center;
              }

              &:last-child {
                border-bottom-right-radius: 0.25rem;
                text-align: center;
              }
            }
          }
        }
      }
    }

    &.unset-height {
      height: unset;
    }

    &.full-height {
      height: 100%;
    }

    .form-row {
      width: 78%;
    }

    .form-row-edit {
      width: 94%;
    }
    .receive-file-list-container {
      .receive-file-note {
        font-size: 14px;
        font-weight: 400;
        color: tokens.$form-title-full;
        .note-label {
          font-weight: 700;
        }
      }
      .custom-card {
        color: rgba(22, 26, 73, 1);
        border-radius: 4px;
        border: none;
      }

      .custom-card-header {
        background-color: tokens.$accordion-header-bg-color;
        color: tokens.$form-color-general;
        font-size: 16px;
        font-weight: 600;
        border-radius: 0;
        border: none;
        cursor: pointer;
        .plus-icon {
          color: tokens.$current-attorney-text;
        }
        :nth-child(2) {
          width: 100% !important;
        }
        .custom-folder-name {
          padding-left: 1.125rem;
        }
      }
      .custom-card-header-bankdetails {
        background-color: tokens.$accordion-header-bg-color;
        color: tokens.$form-color-general;
        font-size: 16px;
        font-weight: 600;
        border-radius: 0;
        border: none;
        cursor: pointer;
        .plus-icon {
          color: tokens.$current-attorney-text;
        }
        :nth-child(2) {
          width: 100% !important;
        }
        .custom-folder-name {
          padding-left: 1.125rem;
        }
      }
      .card-body {
        padding: 0;
      }
      .form-check-input {
        height: 1.125rem;
        width: 1.125rem;
        border: 2.5px solid rgba(85, 92, 110, 255);
      }
      .form-check-input[type="checkbox"] {
        border-radius: 2px;
      }
      .form-check-input:checked {
        background-color: tokens.$tooltip-body;
        //border: rgba(85,92,110,255);
        background-image: url("../assets//images/check-black.svg");
      }
      .form-check-label {
        padding-left: 0.25rem;
      }

      .doctype-table-container {
        max-width: 100%;
        //margin: auto;
        //margin-top: 3rem;
        overflow-x: auto;
      }

      .table-hover tbody tr:hover td {
        background-color: rgba(249, 249, 249, 1);
      }
      table {
        //font-family: "Open Sans", sans-serif;
        position: relative;
        margin-bottom: 0;
        //border-collapse: collapse;
        //border-spacing: 0;
        // table-layout: auto;
        //width: 100%;
        border: 1px solid rgba(232, 232, 232, 1);
        //border-radius: 0.5rem;
        //box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

        // * {
        //     border: none;
        // }
        white-space: nowrap;
        thead tr {
          // color: $gray-800;
          //font-size: 1rem;
          //font-weight: 500;
          //text-align: left;

          th {
            font-size: 14px;
            font-weight: 600;
            color: tokens.$form-label-new;
            //  background: $gray-200;
            //padding: 0.75rem 1.5rem;
            //vertical-align: middle;
          }
        }
        tbody {
          .form-check-inline {
            margin-right: 0;
          }
          // tr:nth-child(odd) td {
          //     //background: $white;
          // }

          // tr:nth-child(even) td {
          //     //background: $gray-200;
          // }
          td {
            font-size: 14px;
            font-weight: 400;
            color: tokens.$form-label-new;
          }
        }

        // tr:last-child td:first-child {
        //     //border-bottom-left-radius: 0.5rem;
        // }

        th:first-child {
          border-top-left-radius: 0.5rem;
        }

        // tr:last-child td:last-child {
        //     //border-bottom-right-radius: 0.5rem;
        // }

        // th:last-child {
        //    // border-top-right-radius: 0.5rem;
        // }

        tr > th:first-child,
        tr > td:first-child {
          //position: sticky;
          //left: 0;
          padding-left: 3rem;
          //min-width: 50%;
        }
      }
    }
  }
}

.firm-title {
  height: 3.5rem;

  .firm-sub-title {
    font-size: 20px;
    font-weight: 700;
    color: tokens.$page-sub-title-color;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.sa-phone-mvs-desktop {
  position: absolute;
  .country-list {
    text-align: left;
  }
}

.tooltip-opacity {
  z-index: 999999;
}
@media (max-width: 576px) {
  .firm-page-outer-container {
    .firm-page-container {
      .update-info-box {
        display: flex;
        position: relative;
        right: 8px;
      }
      .close-button-ripon {
        top: 8px;
        right: 5px;
      }
      .update-info-text {
        padding-right: auto;
      }
      .info-icon-addfamily {
        padding-top: 12px;
      }
      .firm-view-first {
        width: 100%;
        padding-bottom: 10px;
      }

      .form-row {
        margin-left: 0;
        width: 100%;
      }

      .form-row-edit {
        margin-left: 0;
        width: 100%;
      }
      .unset-height {
        height: reset;
      }
      .receive-file-list-container {
        table {
          // tr>th:first-child,tr>td:first-child {
          //     position: sticky;
          //     left: 0;
          //     padding-left: 1rem;
          // }
          tr > th:first-child,
          tr > td:first-child {
            padding-left: 2rem;
          }
        }
        tr > th:first-child,
        tr > td:first-child {
          //position: sticky;
          //left: 0;
          padding-left: 3rem;
          //min-width: 50%;
        }
        .ag-column-first {
          padding-left: 0;
          text-align: revert;
          .ag-header-cell-label {
            display: unset;
          }
        }
        .ag-cell-wrap-text {
          text-align: left;
        }
        .ag-header-cell-label {
          display: unset;
          //justify-content: center;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .firm-view-content {
    &.email-id {
      position: relative !important;
    }
  }
}
@media (max-width: 1024px) and (min-width: 577px) {
  .firm-page-outer-container {
    .firm-page-container {
      .update-info-box {
        margin-top: 10px;
        padding: 8px;
        border: 0px;
        border-radius: 4px;
        background: rgba(251, 212, 210, 1);
        text-align: center;
        width: fit-content;
        position: relative;
        margin-left: auto;
        right: 0;
      }
      .firm-view-first {
        width: 52%;
        padding-bottom: 10px;
      }
      .unset-height {
        height: reset;
      }
      .w-90 {
        width: 90%;
        margin-left: 0;
      }
    }
  }
}

@media only screen and (max-width: 960px) and (min-width: 600px) {
  .firm-page-outer-container {
    .firm-page-container {
      .form-row {
        width: 100%;
        padding-left: 1rem;
      }

      .form-row-edit {
        width: 100%;
        padding-left: 1rem;
      }
    }
  }
}

@media (min-width: 1400px) {
  .firm-page-outer-container {
    &.firm-edit-page-outer-container {
      height: 115vh;
    }
  }
}
