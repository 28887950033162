@use "./fonts.scss";
@use "./colors.scss" as tokens;

.ai-recommendations-doc-list {
  .custom-card {
    color: rgba(22, 26, 73, 1);
    border-radius: 4px;
    border: none;
    margin-bottom: 0px;
  }

  .page-title-ai {
    font-size: 18px;
    font-weight: 600;
  }

  .custom-card-sub {
    color: rgba(22, 26, 73, 1);
    border-radius: 4px;
    border: none;
    margin-bottom: 5rem;
  }

  .custom-card-header {
    background-color: tokens.$accordion-header-bg-color;
    color: tokens.$form-color-general;
    font-size: 16px;
    font-weight: 600;
    border-radius: 0;
    border: none;
    cursor: pointer;
    padding: 1rem;
    margin-bottom: 9px;

    .plus-icon {
      color: tokens.$current-attorney-text;
    }

    &:nth-child(2) {
      width: 100% !important;
    }
  }

  .custom-card-header-subfolder {
    color: tokens.$form-color-general;
    border-radius: 0;
    font-family: Helvetica;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    text-align: left;
    cursor: pointer;
    padding: 1rem;
    margin-bottom: 1rem;

    .plus-icon {
      color: tokens.$current-attorney-text;
    }

    &:nth-child(2) {
      width: 100% !important;
    }
  }

  .card-body-main {
    padding: 0;
    border: 1px solid #e8e8e8;

    .card-body-main .doctype-table-container {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      border: none;
    }

    .card-body-main table {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }

    .card-body-main tbody tr {
      border-bottom: 1px solid #dee2e6;
    }
  }

  .card-body-sub {
    padding: 0;

    .card-body-sub .doctype-table-container {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }

    .card-body-sub table {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }
  .custom-radio-button input[type="radio"] {
    width: 18px;  
    height: 18px;  
    border: 1px solid grey;
  }  
  .custom-radio-button .form-check-input:focus {
    outline: none;
  }
  .custom-radio-button .form-check-input:checked {
    background-color: rgba(235, 88, 22, 1);
    border: rgba(235, 88, 22, 1);
  //  box-shadow: 0 0 0 0.25rem rgb(249, 183, 150);
   box-shadow: none;
  }
}
.folder-name{
  vertical-align : middle
}
.ai-doc-modal {
  // width: 1300px;
  // height: 832px;

  .modal-content {
      border-radius: 8px;
      width: fit-content;
  }

  .share-doc-modal-header {
      border-bottom: none;
      .btn-close {
          background: transparent var(--bs-btn-close-bg) center / 16.5px auto no-repeat;
          color: black;
          margin-right: 2px;
          &:focus {
              outline: none;
              box-shadow: none;
          }
      }
  }

  .share-doc-modal-body {
      padding-top: 40px; 
      padding-right: 30px;
      font-family: Helvetica;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      text-align: justify !important;
  }

  .share-doc-modal-footer {
      border-top: 0;
      .doc-share-submit-btn {
          width: 80px;
          height: 48px;
          font-size: 18px;
          font-weight: 400;
          background-color: rgba(22, 26, 73, 1);
      }
      .doc-share-submit-btn-nobg {
          width: 80px;
          height: 48px;
          font-size: 18px;
          font-weight: 600;
          background-color: white;
          color: rgba(22, 26, 73, 1);
          border: 1px solid rgba(22, 26, 73, 1);;
      }
  }
}
.button-link{
  background: none;
  border: none;
  color: rgba(25, 118, 210, 1);
  text-decoration: underline;
  cursor: pointer;
}
#tooltip-ai {
  position: absolute !important;
  background-color: tokens.$popover-bg;
  // height: 2rem;
  max-width: 400px !important;
  padding: none !important;
  --bs-popover-bg : none !important;
  &.bs-popover-left {
    .popover-arrow {
      &::after {
        border-left-color: tokens.$popover-bg;
      }

      &::before {
        border-left-color: tokens.$popover-bg;
      }
    }
  }

  &.bs-popover-bottom {
    .popover-arrow {
      &::after {
        border-bottom-color: tokens.$popover-bg;
      }

      &::before {
        border-bottom-color: tokens.$popover-bg;
      }
    }
  }

  &.bs-popover-top {
    .popover-arrow {
      &::after {
        border-top-color: tokens.$popover-bg;
      }

      &::before {
        border-top-color: tokens.$popover-bg;
      }
    }
  }

  &.bs-popover-right {
    .popover-arrow {
      &::after {
        border-right-color: tokens.$popover-bg;
      }

      &::before {
        border-right-color: tokens.$popover-bg;
      }
    }
  }

  &.bs-popover-start {
    .popover-arrow {
      &::after {
        border-left-color: tokens.$popover-bg;
      }

      &::before {
        border-left-color: tokens.$popover-bg;
      }
    }
  }

  .popover-body {
    color: tokens.$tooltip-body;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    font-family: "HelveticaRegular", "Arial", sans-serif;
    --bs-popover-body-padding-y: 8px;
    overflow: hidden;
    ul {
      margin: 0;
      padding: 0px;

      li {
        color: tokens.$tooltip-body;
      }
    }
  }
}

.ai-dragndrop-modal {
  --bs-modal-footer-gap: 0 !important;
  --bs-modal-header-padding: 0 !important;

  height: 350px;
  .dashes{
    flex: 1;
    height: 1px;
    background-image: repeating-linear-gradient(to right, rgb(33, 37, 41) 0%, rgb(33, 37, 41) 10px, transparent 10px, transparent 18px);
  }

  .modal {
    --bs-modal-header-padding: 0;
  }
  .btn-close {
    position: absolute;
    z-index: 999999999 !important;
    top: 12px;
    right: 12px;
  }
  .is-will-uploaded {
    font-family: "HelveticaRegular", "Arial", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.1px;
    text-align: center;
  }

  .is-personal-use{
    font-family: Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.1px;
  text-align: center;
  margin-bottom: 20px;
  }

  .modal-footer-willupload {
    font-family: "HelveticaRegular", "Arial", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 19.6px;
    text-align: center;
  }
}