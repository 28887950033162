@use "./fonts.scss";
@use "./colors.scss" as tokens;

.sidebar-mb {
    width: 68% !important;
    z-index: 99999;

    .bg-color {
        background-color: tokens.$header-background-color;
    }

    .offcanvas-header {
        background-color: tokens.$header-background-color;
    }

    .active {
        border-radius: 2px;
    }

    .btn-close {
        color: #fff
    }    
}

#firm-sidebar {
    margin-left: -1rem;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;

    .firm-sub-title {
        font-size: 20px;
        font-weight: 700;
        color: tokens.$page-sub-title-color;
    }

}

@media (max-width: 576px) {
    #firm-sidebar {
        margin-left: unset;
    }

    .sidebar-mb {
        .txt-color {
            color: #fff !important;
            padding-bottom: 0.5rem;
        }

        .navbar-nav {
            .nav-link {
                padding-bottom: 1rem;

                &.active {
                    span {
                        border-bottom: 3px solid tokens.$header-nav-link-border-color;
                    }
                }
            }
        }

        .close-icon {
            left: 84%;
            top: 8%;
        }
    }
}

@media (max-width: 400px) {
    .sidebar-mb {
        width: 70% !important;

    }
}

@media only screen and (max-width: 960px) and (min-width: 600px) {
    .sidebar-mb {
        width: 36% !important;

        .txt-color {
            color: #fff !important;
            padding-bottom: 0.5rem;
        }

        .navbar-nav {
            .nav-link {
                padding-bottom: 1rem;

                &.active {
                    span {
                        border-bottom: 3px solid tokens.$header-nav-link-border-color;
                    }
                }
            }
        }

        .close-icon {
            left: 84%;
            top: 8%;
        }
    }
}