#root {
    height: 100%;
}

.custom-not-found-container {
    .not-found-number {
        color: rgba(0, 0, 0, 1);
        font-size: 150px;   
        font-weight: 700;
    }
    .not-found-text {
        font-size: 16px;
        font-weight: 400px;
        color: rgba(0, 0, 0, 1);
    }
}