.Toastify__toast-container {
    width: 26vw !important;
}

@media (max-width: 568px) {
    .Toastify__toast-container {
        width: 100% !important;
    }
}

@media (min-width: 568px) and (max-width: 1024px) {
    .Toastify__toast-container {
        width: 45vw !important;
    }
}