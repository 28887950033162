@use "./colors.scss" as tokens;

.result-text{
    font-family: "HelveticaRegular", "Arial", sans-serif;
    font-size: 15px;
    font-weight: 600;
    line-height: 25.2px;
    text-align: center;
    color: #333333;

}
.result-subtext{
font-size: 16px;
font-weight: 400;
line-height: 25.2px;
text-align: center;

}
.modal-popup-container-type-4{
    .modal-content{
        width: 79%;
    }
    .btn-close{
        width: 18px;
        height: 18px;
        top: 15px;
        right: 15px;
        position: absolute;
    }
    .modal-header{
        margin-top: 3px;
        padding-bottom: 2px;
    }
    .modal-custom-title {
        font-family: "HelveticaRegular", "Arial", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.6px;
        text-align: left;
        margin-bottom: 5px;
    }
    .modal-label{
        text-align: left;
    }
    .form-input{
        border-radius: 4px !important;
        height: 40px !important;
    }
    .mui-date-picker-container {
        .MuiInputBase-root{
            height: 40px !important;
        }
        .MuiOutlinedInput-notchedOutline{
            border-radius: 4px !important;
        }
    }
    .mui-date-picker-invalid-container {
        .MuiInputBase-root{
            height: 40px !important;
        }
        .MuiOutlinedInput-notchedOutline{
            border-radius: 4px !important;
        }
    }
    .custom-button {
        border-radius: 4px !important;
        width: 140px;
        .btn-standard{
            &:hover {
                background-color: tokens.$form-button-blue-hover;
              }
          
              &:active {
                background-color: tokens.$form-button-blue-hover;
                box-shadow: none;
          
                &:focus {
                  background-color: tokens.$form-button-blue-hover;
                  box-shadow: none;
                  color: white
                }
              }
          
              &:focus {
                background-color: tokens.$form-button-blue-hover;
                box-shadow: none;
                color: white
              }
        }

    }
    .divider-firm {
        width: 90%;
        align-self:center;  
        height: 0.02rem;
        background-color: rgba(208, 211, 214, 1);
      }
      .description{
        font-family: "HelveticaRegular", "Arial", sans-serif;
        font-size: 16px;
        font-weight: 400;
      }
      .phone-input-invalid-container-modal{
        .react-tel-input .form-control{
          font-size: 1rem;
          border: 0.063rem solid tokens.$form-error-general !important;
          height: 48px;
          border-radius: 4px;
          color: tokens.$form-color-general;
          width: 100%;
        }
        :focus {
          box-shadow: 0 0 0 4px rgb(248, 207, 207);
        }
      }
      .phone-input-valid-container-modal{
        .react-tel-input .form-control{
            font-size: 1rem;
            height: 48px;
            border-radius: 4px;
            color: tokens.$form-color-general;
            width: 100%;
          }
          :focus {
            box-shadow: 0 0 0 4px rgb(248, 207, 207);
          }
        }
}

.modal{
    z-index: 999999 !important;
}
.modal-backdrop{
    z-index: 999999 !important;
}
.MuiPickersPopper-root{
    z-index: 999999 !important;
}
.MuiDialog-root{
    z-index: 999999 !important;
}
.MuiDateCalendar-root {
    width: 100% !important;
    height: 100% !important;
  }
@media (max-width: 568px){
    .modal-popup-container-type-4{
        .modal-dialog{
            max-width: 96% ;
            justify-content: center;
        }
        .modal-content{
            width: 96%;
        }
    }
}

