@use "./colors.scss" as tokens;

.password-rules{
    font-family: "HelveticaRegular", "Arial", sans-serif;
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    .password-rules-item{
        border:0;
        padding: 0;
        color: tokens.$font-color-dashboard;    
    }
}