@use "./colors.scss" as tokens;

#Short-certificate-modal {
  .modal-content {
    padding: 0 !important;
    .modal-custom-header {
      border: none;
    }
  }
  .modal-content-container {
    padding: 0 !important;
    .fonnt-16 {
        font-size: 16px;
    }
  }
}
