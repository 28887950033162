@use "./colors.scss" as tokens;

.dropbtn {
  cursor: pointer;
}

.dropbtn-bulkshare{
  cursor: pointer;
  width: 16px;
  height: 14px;
}
.dropdown {
  position: relative;
  display: inline-block;
  font-family: "HelveticaRegular", "Arial", sans-serif;
  font-size: 16px;

  .badge-success-position {
    width: 20px;
    height: 20px;
    position: absolute !important;
    top: -2px;
    left: 14px;
    --bs-badge-padding-x: 4px;
    --bs-badge-padding-y: 3px;
  }  
}
.custom-tooltip{
  position: relative;
  left: 200;
    .tooltip-inner {
      background-color: #fff !important;
      color: #000 !important;
      border: 1px solid #062e56;
    }
    .tooltip-arrow::before {
      border-bottom: 5px solid #fff !important;
      position: absolute;
      right:10px;
    }
}
.custom-tooltip-medicalprofessional{
  position: relative;
  left: 200;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
  border-radius: 4px;
  margin-top:8px !important;
    .tooltip-inner {
      background-color: #fff !important;
      color: #000 !important;
      padding: 15px !important;
      font-family: "HelveticaRegular", "Arial", sans-serif;
      font-size: 12px;
      font-weight: 500;
      line-height: 16.8px;
      text-align: center; 
    }
    .tooltip-arrow::before {
      border-bottom: 5px solid #fff !important;
      position: relative;
      right:10px;
      top:0px;
    }
}
.dropdown-content {
  position: fixed;
  background-color: tokens.$dropdown-bg;
  width: 210px;
  right: 2%;
  top: 4rem;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.25);
  z-index: 1;
  &.custom-content{
    width: 272px;
    border-radius: 10px;
    //height: 19rem;
    overflow: auto;
    //box-shadow: 1px 0px 3px 2px rgba(0, 0, 0, 0.2);
    .title-container{
      background: #D3E4FF;
      padding-bottom: 6px;
      padding-top: 8px;
    }
    .sticky-header {
      position: sticky;
      top: 0;
      z-index: 10;
    }
    
    .title{
      font-size: 14px;
      font-weight: 700;
      color:tokens.$form-placeholder-color-type2;
      &.hover{
        background-color: tokens.$dropdown-title-hover-bg-color;
      }
    }
    .clear-all{
      font-size: 14px;
      font-weight: 400;
      color:tokens.$form-placeholder-color-type2;
      text-decoration: underline;
    }
    .document-details{
      .document-type{
        font-size: 14px;
        font-weight: 400;
        color:tokens.$form-placeholder-color-type2;
        text-decoration: underline;
      }
      .updated-time{
        font-size: 14px;
        font-weight: 400;
        color:tokens.$form-placeholder-color-type2;
        }
        .notification-unread {
          font-weight: bold; 
        }
    }
  }
  &.dropdown-scroll{
    max-height: 20rem;
    overflow: auto;
  }
  &.dropdown-scroll::-webkit-scrollbar-track
    {
        width: 6px;
        -webkit-box-shadow: inset 0 0 6px rgb(19, 24, 68);
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        background-color: #F5F5F5;
        height:10px
    }

    &.dropdown-scroll::-webkit-scrollbar
    {
        width: 6px;
        height: 10px;
        background-color: #F5F5F5;
    }

    &.dropdown-scroll::-webkit-scrollbar-thumb
    {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
        width: 6px;
        -webkit-box-shadow: inset 0 0 6px rgb(19, 24, 68);
        background-color: #555;
    }
  ul {
    list-style-type: none;
    padding-left: 0;

    li {
      cursor: pointer;
      &:first-child {
        border-bottom: 1px solid #E9EAEB;
      }

      &:nth-child(2) {
        border-bottom: 1px solid #E9EAEB;
      }

      &:hover {
        background-color: #F4F5F6;
      }
    }
  }

  .disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }

  .ul-hidden {
    display: none;
  }

  .ul-show {
    display: block;
    margin-bottom: 0;
  }

}

.notification-container {
  top: 2px;
  .bell-icon{
    cursor: pointer;
  }
  .custom-badge {
    top: -6px;
    left: 16px;
  }
}

.dropdown-content ul li {
  color: tokens.$form-color-general;
  padding: 10px 10px;
  display: block;
  &.pd{
    padding: 6px 10px !important;
  }
}
.no-notifications {
  margin-top:10px;
  padding: 10px;
  text-align: center;
  color: #888;
  font-size: 14px;
}

@media (max-width: 960px){
  .custom-tooltip{
    display: none !important;
  }
  .custom-tooltip-medicalprofessional{
    display: none !important;
  }
}