@use "./colors.scss" as tokens;

.otp-verification-container {
  .login-web-image {
    width: 100%;
    height: 100vh;
    background-image: url("../assets/images/otp-bg.webp");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .inner-otp-container {
    width: 70%;
    margin-top: 200px;
  }
  .inner-shareotp-container{
    width: 70%;
    margin-top: 150px;
  }
  .custom-label-otp {
    color: tokens.$form-label-new !important;

    .f-w-700 {
      font-weight: 700;
    }
  }

  .custom-label-shareotp{
    font-family: Helvetica;
    font-size: 16px;
    font-weight: 400;
    line-height: 25.2px;
    text-align: left;
    color: tokens.$form-label-new !important;
  }

  .form-input-otp {
    font-size: 1.25rem;
    border: 0.063rem solid tokens.$form-input-border-new;
    width: 15%;
    height: 3rem;
    padding: 0.875rem 0.75rem 0.875rem 1rem;
    border-radius: 4px;

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }

    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }

    &::placeholder {
      color: tokens.$form-color-general;
    }
  }

  .otp-verify-error {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 12px;
    color: tokens.$form-error-general;
  }

  .custom-resend-otp {
    font-size: 12px;
    font-weight: 400;
    color: rgba(48, 47, 86, 1);

    .btn-resent-otp {
      text-decoration: underline;
      opacity: 1;

      &.disabled {
        pointer-events: none;
        cursor: none;
        color: rgba(48, 47, 86, 0.6);
      }

      &.active {
        pointer-events: all;
        cursor: pointer;
      }
    }
  }

  .custom-resend-shareotp {
    font-family: Helvetica;
    font-size: 12px;
    font-weight: 400;
    line-height: 25.2px;
    text-align: left;

    .btn-resent-shareotp {
      text-decoration: underline;
      opacity: 1;
      font-weight: 700;
      &.disabled {
        pointer-events: none;
        cursor: none;
        color: rgba(48, 47, 86, 0.6);
      }

      &.active {
        pointer-events: all;
        cursor: pointer;
      }
    }
  }

  .btn-submit {
    width: 100%;
  }

  .btn-shareotp-submit {
    width: 35%;
  }
}

@media (max-width: 280px) {
  .otp-verification-container {
    .form-input-otp {
      width: 14%;
      padding: 0;
      text-align: center;
    }
    
  }
}

@media (max-width: 576px) {
  .otp-verification-container {
    .inner-otp-container {
      width: 100%;
      margin-top: 200px;
    }
    .inner-shareotp-container {
      width: 100%;
      margin-top: 200px;
    }
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .otp-verification-container {
    .justify-align-container {
      justify-content: end !important;
      padding-right: 50px;
    }
    .inner-otp-container {
      width: 60%;
      margin-top: 200px;
    }
    .inner-shareotp-container {
      width: 60%;
      margin-top: 150px;
    }
  }
}

@media (max-width: 1200px) and (min-width: 992px) {
  .otp-verification-container {
    .inner-otp-container {
      width: 80%;
      margin-top: 200px;
    }
    .inner-shareotp-container {
      width: 80%;
      margin-top: 150px;
    }
  }
}

@media (min-width: 1200px) {
  .otp-verification-container {
    .inner-otp-container {
      width: 60%;
      margin-top: 200px;
    }
    .inner-shareotp-container {
      width: 70%;
      margin-top: 150px;
    }
  }
}

@media (min-width: 1600px) {
  .otp-verification-container {
    .inner-otp-container {
      width: 50%;
      margin-top: 200px;
    }
    .inner-shareotp-container {
      width: 50%;
      margin-top: 150px;
    }
  }
}
