.signup-tabs {
    font-family: "HelveticaRegular", "Arial", sans-serif;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 6px;
    .tab-link {
        font-size: 16px;
        font-weight: 700;
        color: #0F477E;
        text-align: center;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 0;
        &.active {
            color: #0F477E;
        }
    }

    .nav-link {
        color: #0F477E;
        &:hover {
            color: #0F477E;
        }
    }

    .active-tab {
        border-bottom: 5px solid #2B3348;
    }
}