@use "./fonts.scss";
@use "./colors.scss" as tokens;

.ag-theme-alpine .ag-layout-auto-height .ag-center-cols-viewport, .ag-theme-alpine .ag-layout-auto-height .ag-center-cols-container, .ag-theme-alpine .ag-layout-print .ag-center-cols-viewport, .ag-theme-alpine .ag-layout-print .ag-center-cols-container, .ag-theme-alpine-dark .ag-layout-auto-height .ag-center-cols-viewport, .ag-theme-alpine-dark .ag-layout-auto-height .ag-center-cols-container, .ag-theme-alpine-dark .ag-layout-print .ag-center-cols-viewport, .ag-theme-alpine-dark .ag-layout-print .ag-center-cols-container, .ag-theme-alpine-auto-dark .ag-layout-auto-height .ag-center-cols-viewport, .ag-theme-alpine-auto-dark .ag-layout-auto-height .ag-center-cols-container, .ag-theme-alpine-auto-dark .ag-layout-print .ag-center-cols-viewport, .ag-theme-alpine-auto-dark .ag-layout-print .ag-center-cols-container {
  min-height: 80px;
}
.ag-pinned-left-header{
  border:none;
}

.ag-theme-alpine {
  font-family: "HelveticaRegular", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  justify-content: center;
  margin-bottom: 20px;
  height: 100%;

  .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
    border-right: 0;
  }
  .ag-checkbox-input-wrapper.ag-checked::after{
    color:rgba(235, 88, 22, 1) !important
  }
  // .ag-theme-material .ag-icon-checkbox-checked {
  //   color: red; }
  .ag-row-odd {
    background-color: tokens.$ag-grid-table-odd-row-bg;
  }

  .grid-firm-name-anchor {
    color: tokens.$ag-grid-text;
    text-decoration: underline;
    text-underline-offset: 3px;
    cursor: pointer;
    width: fit-content;
  }

  .grid-firm-email-anchor {
    color: tokens.$ag-grid-text;
    width: fit-content;
  }

  .grid-active-button {
    .btn-primary {
      background-color: tokens.$ag-grid-active-text-bg;
      color: tokens.$ag-grid-active-text;
      border: 0;
      border-radius: 8px;
      padding: 1px 25px;
      font-size: 12px;
      font-weight: 600;
      cursor: default;
      text-transform: capitalize;
      text-wrap: nowrap;
      width: 90px;
      height: 20px;
    }

    .btn-primary:hover {
      cursor: default;
    }
  }

  .grid-inactive-button {
    .btn-primary {
      background-color: rgba(255, 239, 238, 1);
      color: rgba(204, 0, 0, 1);
      border: 0;
      border-radius: 8px;
      padding: 1px 25px;
      font-size: 12px;
      font-weight: 600;
      cursor: default;
      text-transform: capitalize;
      text-wrap: nowrap;
      width: 90px;
      height: 20px;
    }

    .btn-primary:hover {
      cursor: default;
    }
  }

  .underlined-header {
    text-decoration: underline;
  }

  .ag-root-wrapper {
    border: 0;
  }

  .ag-header {
    border-bottom: 0;
    border-right: 0;
  }

  .ag-body-viewport {
    border-top: none;
    overflow-x: auto;
    white-space: nowrap;

  }

  .custom-header {
    background-color: tokens.$ag-grid-background;
    color: tokens.$ag-grid-tab-bg;
    font-weight: 700;
    height: 43px;
  }

  .ag-header-cell:hover {
    background-color: transparent !important;
  }

  .ag-header-cell {
    border: 0;
    background-color: tokens.$ag-grid-background;
    .ag-header-select-all{
      margin-right: 0.75rem;
    }
  }

  .ag-horizontal-left-spacer{
    overflow-x: hidden;
  }

  .ag-paging-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    border: 0;

    .ag-paging-row-summary-panel-number {
      font-weight: 400;
    }

    .ag-paging-number {
      font-weight: 400;
    }

    .ag-picker-field-wrapper {
      border-radius: 2px;
      width: 57px;
      height: 40px;
    }

    .ag-paging-page-size {
      display: inline-block;
    }

    .ag-paging-page-size,
    .ag-paging-row-summary-panel {
      padding: 5px 10px;
      border-radius: 5px;
      display: none;
    }

    .ag-paging-page-summary-panel {
      padding: 5px 10px;
      border-radius: 5px;
      margin-left: auto;
      /* Pushes it to the right */
    }
  }

}