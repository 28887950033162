$font-blue : rgba(80, 110, 177, 1);
$font-brand: rgba(48, 47, 86, 1);
$font-anchor-var-1: rgba(48, 47, 86, 1);
$font-anchor-var-2: rgba(19, 89, 158, 1);

$nav-divider: rgba(190, 190, 190, 1);

$form-placeholder-color: rgba(16, 30, 63, 1);
$form-placeholder-error-color: rgba(255, 8, 8, 0.8);
$form-input-border: rgb(80, 110, 177);
$form-bottom-text: rgba(48, 47, 86, 1);
$form-label-text: rgba(21, 52, 120, 1);
$form-helper-text-normal: rgba(26, 61, 139, 1);
$form-helper-text-bold: rgba(21, 52, 120, 1);
$form-title-full: rgba(0, 0, 0, 1);
$form-title-full-border: rgba(193, 201, 210, 1);

$form-input-border-type2: rgba(117, 122, 135, 1);
$form-placeholder-color-type2: rgba(51, 51, 51, 1);

$font-color-dashboard: rgba(51, 51, 51, 0.8);
$dashboard-subtitle-border: rgba(193, 201, 210, 0.8);
$button-primary: rgba(19, 89, 158, 1);
$error-text-color: rgba(220, 53, 69, 0.8);
$tabs-border-color: rgba(121, 121, 121, 0.8);
$active-tab-border: rgba(43, 51, 72, 0.8);
$dashboard-tab-border: rgb(0, 0, 0, 0.2);
$dashboard-active-tab-color: #13599E;
$white-color: rgba(255, 255, 255, 0.8);

$tooltip-body: rgba(255, 255, 255, 1);

// Form New Colors
$form-title-underline-new: rgba(103, 171, 86, 1);
$form-label-new: rgba(51, 51, 51, 1);
$form-input-border-new: rgba(117, 122, 135, 1);
$font-blue-new: rgba(22, 26, 73, 1);
$form-color-general: rgba(51, 51, 51, 1);
$form-error-general: rgba(204, 0, 0, 1);
$form-button-blue: rgba(19, 24, 68, 1);
$form-button-blue-hover: rgba(45, 49, 91, 1);
$header-background-color: rgb(22, 26, 73);
$header-nav-link-border-color: rgb(104, 171, 86);
$page-sub-title-color: rgb(51, 51, 51);
$page-sub-title-bar-bg: rgba(250, 250, 250, 0.8);
$page-sub-title-bar-border: rgba(217, 217, 217, 0.8);
$form-underline-type-2: rgba(80, 184, 74, 1);

$form-page-container-bg : rgba(255, 255, 255, 1);
$dropdown-bg: rgba(255, 255, 255, 1);
$breadcrum-color: rgba(25, 118, 210, 1);

//Firm Details Landing Table
$ag-grid-text: rgba(0, 0, 0, 1);
$ag-grid-background: rgba(232, 232, 237, 1);
$ag-grid-active-text: rgba(0, 128, 0, 1);
$ag-grid-active-text-bg: rgba(204, 230, 204, 1);

$ag-grid-tab-text: rgba(255, 255, 255, 1);
$ag-grid-tab-bg: rgba(22, 26, 73, 1);
$ag-grid-table-odd-row-bg: rgba(208, 211, 214, 0.15);

$ag-grid-search-filter-border-bg:rgba(177, 180, 187, 1);
$folders-hover-bg: rgba(32,174,92);
$popover-bg:rgba(43, 51, 72, 1);
$current-attorney-text:rgba(81, 88, 105, 1);
$dropdown-title-hover-bg-color:rgba(211, 228, 255, 1);
$accordion-header-bg-color:rgba(250, 250, 250, 1);
$radio-button-share-bank-detail:rgba(235, 88, 22, 1);