@use "./colors.scss" as tokens;

.change-password-container {
  .change-pwd-web-image {
    width: 100%;
    height: 100vh;
    background-image: url("../assets/images/change-pwd-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .inner-changepassword-container {
    width: 70%;
    margin-top: 150px;
  }
}

@media (max-width: 576px) {
  .change-password-container {
    .inner-changepassword-container {
      width: 100%;
      margin-top: 150px;
    }
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .change-password-container {
    .justify-align-container {
      justify-content: end !important;
      padding-right: 50px;
    }
    .inner-changepassword-container {
      width: 60%;
      margin-top: 150px;
    }
  }
}

@media (max-width: 1200px) and (min-width: 992px) {
  .change-password-container {
    .inner-changepassword-container {
      width: 80%;
      margin-top: 150px;
    }
  }
}

@media (min-width: 1200px) {
  .change-password-container {
    .inner-changepassword-container {
      width: 70%;
      margin-top: 150px;
    }
  }
}

@media (min-width: 1600px) {
  .change-password-container {
    .inner-changepassword-container {
      width: 50%;
      margin-top: 150px;
    }
  }
}
