@use "./fonts.scss";
@use "./colors.scss" as tokens;

.personal-use-doc-list{
    .receive-file-note{
        font-size: 14px;
        font-weight: 400;
        color:tokens.$form-title-full;
        .note-label{
            font-weight: 700;
        }
    }
    .custom-card {
        color: rgba(22, 26, 73, 1);
        border-radius: 4px;
        border: none;
    }
    .custom-card-sub {
        color: rgba(22, 26, 73, 1);
        border-radius: 4px;
        border: none;
        
    }

    .custom-card-header {
        background-color: tokens.$accordion-header-bg-color;
        color: tokens.$form-color-general;
        font-size: 16px;
        font-weight: 600;
        border-radius: 0;
        border: none;
        cursor: pointer;
        .plus-icon{
            color:tokens.$current-attorney-text
        }
        :nth-child(2){
            width: 100% !important;     
        }
        
    }
    .custom-card-header-subfolder {
        color: tokens.$form-color-general;
        border-radius: 0;
        font-family: Helvetica;
        font-size: 14px;
        font-weight: 400;
        line-height: 19.6px;
        text-align: left;
        cursor: pointer;
        .plus-icon{
            color:tokens.$current-attorney-text
        }
        :nth-child(2){
            width: 100% !important;     
        }
    }

    .card-body-main{
        padding:0;
        border: 1px solid #E8E8E8;
        .card-body-main .doctype-table-container {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
            border: none;
          }
          
          .card-body-main table {
            width: 100%;
            margin-left: 0;
            margin-right: 0;

          }
          
          .card-body-main tbody tr {
            border-bottom: 1px solid #dee2e6; /* This will ensure the border extends full width */
          }
    }
    .card-body-sub{
        padding:0;
        
        .card-body-sub .doctype-table-container {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
          }
          
          .card-body-sub table {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
          }
          
    }
    .form-check-input {
        height: 1.125rem;
        width: 1.125rem;
        border: 2.5px solid rgba(85,92,110,255);
        
    }
    .form-check-input[type=checkbox]{
        border-radius: 2px;
    }
    .form-check-input:checked {
        background-color: tokens.$tooltip-body;
        background-image: url("../assets//images/check-black.svg");
        border: 2.5px solid rgb(85, 92, 110);
        box-shadow: none;
    }
    .form-check-label{
        padding-left: 0.25rem;
    }
    
    .doctype-table-container{
        max-width: 100%;
        overflow-x: auto;
    }
    
    .table-hover tbody tr:hover td{
        background-color: rgba(249, 249, 249, 1);
      }
    table {
        position: relative;
        margin-bottom: 0;
        width: 100%;
        white-space: nowrap;
        thead tr {          
            th {
                font-size: 14px;
                font-weight: 600;
                color:tokens.$form-label-new
            }
        }
        tbody {
            
            .form-check-inline{
                margin-right: 0;
            }            
            td {
                font-size: 14px;
                font-weight: 400;
                color:tokens.$form-label-new
            }
        }
    
        th:first-child {
            border-top-left-radius: 0.5rem;
        }
      tr>th:first-child,tr>td:first-child {
        padding-left:3rem;
      }
    }   
}