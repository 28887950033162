@use "./fonts.scss";
@use "./colors.scss" as tokens;

.sa-button {
  width: 100%;
  background-color: tokens.$ag-grid-background;
  color: tokens.$ag-grid-tab-bg;
  font-weight: 700;
  height: 100%;
  font-size: 16px !important;
  text-wrap: nowrap;
}
#search-firm-input-field-mob {
  width: 100%;
}
.btn-bulk-upload {
  margin-right: 10px;
}
.action-button {
  cursor: pointer;
}
.tooltip {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index:999999 !important;
}

.tooltip-inner {
  background-color: tokens.$form-button-blue !important;
  color: white !important;
  font-size: 12px;
  padding: 4px !important;
}
.tooltip.show .tooltip-arrow::before {
  border-top-color: tokens.$form-button-blue !important;
}
.tooltip.show {
  opacity: 1 !important;
}
.client-dropdown-tooltip{

  &.tooltip{
    position: relative;
  //right: 200;
    .tooltip-inner {
      font-family: "HelveticaRegular", "Arial", sans-serif;
        background-color: rgba(221, 221, 221, 1) !important;
        color: tokens.$ag-grid-text !important;
        font-size: 12px;
        font-weight: 400;
        border-radius: 4px;
        padding: 6px !important;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    }
    &.show{
      .tooltip-arrow{
        position: absolute;
        top:20px !important;
        transform: none !important;
      }
      .tooltip-arrow::before{    
        border-left-color:rgba(221, 221, 221, 1) !important;
        border-top-color:transparent !important;      
      }
    }
    
    .tooltip-arrow::before{    
      border-left-color:transparent !important;
      border-top-color:transparent !important;      
    }
  }
  }

.sa-table-label {
  font-size: 18px;
  font-weight: 700;
  color: tokens.$page-sub-title-color;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sa-search-container {
  width: 350px;
  :focus {
    outline: none;
    box-shadow: 0 0 2px 2px rgb(192, 192, 193); /* Adjust the blur radius and color as needed */
  }
  position: relative;
}
.tooltip-inner {
  background: red;
}

.sa-action-button {
  display: inline-block;
}


@media (max-width: 960px){
  .client-dropdown-tooltip{
    display: none !important;
  }
  .tooltip{
    display: none !important;
  }
}