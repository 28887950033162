@use "./colors.scss" as tokens;
#modal-popup-container-type-2 {
    width: 530px;
    .modal-content {
        .modal-custom-header {
            border: none;

            .modal-custom-title {
                font-size: 20px !important;
                color: rgba(21, 52, 120, 1) !important;
                font-weight: 400 !important;
            }
            .modal-custom-title2 {
                font-size: 20px !important;
                color: rgba(51, 51, 51, 1) !important;
                font-weight: 400 !important;
            }
            .btn-close {
                width: 22px;
                height: 22px;
                background: transparent url("../assets/images/close-btn-modal.svg") center/1em auto no-repeat;
                outline: none;

                &:focus {
                    outline: 0;
                    box-shadow: none;
                }
            }
        }

        .modal-body {
            .modal-content-section {
                font-size: 16px;
                color: rgba(21, 52, 120, 1);
                font-weight: 400;
            }
            .modal-content-section2 {
                font-size: 16px;
                color: rgba(51, 51, 51, 1);
                font-weight: 400;
            }
            .modal-button-section {
                .custom-popup-button {
                    border: 1px solid rgba(22, 26, 73, 1);
                    background-color: rgba(22, 26, 73, 1);
                    font-weight: 600;
                    font-size: 16px;
                    border-radius: 0px;
                    height: 40px;

                    &.btn-yes {
                        color: #FFFFFF;
                    }

                    &.btn-no {
                        color: #161A49;
                        background-color: #FFFFFF;
                    }

                    &.left-radius {
                        border-top-left-radius: 4px;
                        border-bottom-left-radius: 4px;
                    }

                    &.right-radius {
                        border-top-right-radius: 4px;
                        border-bottom-right-radius: 4px;
                    }
                }
            }
        }
    }
}

.modal-backdrop.fade {
    opacity: 0.7 !important;
}
.modal-backdrop{
    z-index: 999999 !important;
}
#modal-popup-container-search {
    width: 480px;
    .modal-content {
        .modal-custom-header {
            border: none;
            padding-bottom:0 ;
            .modal-custom-title {
                font-size: 20px !important;
                color: tokens.$form-title-full !important;
                font-weight: 400 !important;
            }


            .btn-close {
                width: 22px;
                height: 22px;
                background: transparent url("../assets/images/close-btn-modal.svg") center/1em auto no-repeat;
                outline: none;

                &:focus {
                    outline: 0;
                    box-shadow: none;
                }
            }
        }

        .modal-body {
            padding-top:0 ;
            .modal-content-container{
                padding-bottom: 1rem !important;
                .modal-content-section {
                    font-size: 16px;
                    color: rgba(21, 52, 120, 1);
                    font-weight: 400;
                    .employ-data-title{
                        font-size: 14px;
                        font-weight: 700;
                        color: tokens.$form-title-full;
                    }
                    .overflow-div{
                        //width: 100%;
                        overflow-x: auto;
                        .attorney-details{
                            
                            .radio-button-attorney{
                                width: 4%;
                            }
                            .radio-button-details{
                                ul {
                                    list-style-type: none;
                                    
                                    li{
                                        font-size: 1rem;
                                        font-weight: 400;
                                        color: tokens.$form-title-full;
                                    }
                                  }
                            }
                            .attorney-text{
                                font-size: 1rem;
                                font-weight: 400;
                                color: tokens.$form-title-full;
                                white-space: nowrap;
                            }
                        }
                    }
                    .overflow-div::-webkit-scrollbar-track
                    {
                        -webkit-box-shadow: inset 0 0 6px rgb(19, 24, 68);
                        border-radius: 10px;
                        background-color: #F5F5F5;
                    }

                    .overflow-div::-webkit-scrollbar
                    {
                        height: 8px;
                        background-color: #F5F5F5;
                    }

                    .overflow-div::-webkit-scrollbar-thumb
                    {
                        border-radius: 10px;
                        -webkit-box-shadow: inset 0 0 6px rgb(19, 24, 68);
                        background-color: #555;
                    }
                }
            }
           

            .modal-button-section {
                .custom-popup-button {
                    border: 1px solid rgba(22, 26, 73, 1);
                    background-color: rgba(22, 26, 73, 1);
                    font-weight: 600;
                    font-size: 16px;
                    border-radius: 0px;
                    height: 40px;

                    &.btn-yes {
                        color: #FFFFFF;
                    }

                    &.btn-no {
                        color: #161A49;
                        background-color: #FFFFFF;
                    }

                    &.left-radius {
                        border-top-left-radius: 4px;
                        border-bottom-left-radius: 4px;
                    }

                    &.right-radius {
                        border-top-right-radius: 4px;
                        border-bottom-right-radius: 4px;
                    }
                }
            }
        }
    }
}
#style-1::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgb(19, 24, 68);
	border-radius: 10px;
	background-color: #F5F5F5;
}
@media (max-width: 568px) {
    #modal-popup-container-type-2 {
        width: 96%;
    }
    #modal-popup-container-search{
        width: 96%;
    }
}