.custom-card-filetransfer {
  width: 198px;
  height: 40px;
  border-radius: none;
  --bs-card-cap-bg: rgba(232, 241, 251, 1);
  --bs-border-radius: none;
  border: 1px solid rgba(117, 122, 135, 1);
}

.custom-card-header-filetransfer {
  background: rgba(232, 241, 251, 1) !important;
  color: rgba(22, 26, 73, 1);
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;
  border: none;
  cursor: pointer;
  border-radius: none;
}

.custom-card-body-filetransfer {
  width: 100% !important;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  border: none;
  margin: 0px !important;
  padding: 0px !important;
  border-radius: none;
}

.dropdown {
  .dropdown-menu.show {
    .dropdown-item {
      width: 198px !important;
      text-wrap: wrap;
      height: auto !important;
      font-size: 14px;
      padding-left: 30px !important;
    }
  }
}
