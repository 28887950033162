@use "./fonts.scss";
@use "./colors.scss" as tokens;


.superadmin-container {
    background-color: tokens.$page-sub-title-bar-bg;
    padding-top: 64px;

    .firm-page-outer-container {
        background-color: rgb(255, 255, 255);
        &.firm-page-add-outer-container {
            height: 89%;
        }
        &.firm-page-edit-outer-container {
            height: 89%;
        }
        &.firm-page-bulk-outer-container {
            height: 89%;
        }
    }

    .employee-title-page-container {
        background-color: tokens.$tooltip-body;
        border-bottom: 1px solid tokens.$page-sub-title-bar-border;

        .page-title {
            font-size: 20px;
            font-weight: 700;
        }
    }

    .employee-title-viewpage-mb-container {
        background-color: #E8E8ED;

        // border-bottom: 1px solid tokens.$page-sub-title-bar-border;  
        .page-title {
            font-size: 16px;
            font-weight: 600;
        }
    }

   
}
.employee-view-container {
    background-color: tokens.$tooltip-body;

    .view-details {
        width: 70%;
        &.family-details{
            width: 85% ;
        }
    }

    .emp-view-first {
        width: 35%;
    }

    .firm-view-label {
        font-family: "HelveticaRegular", "Arial", sans-serif;
        font-size: 1rem;
        font-weight: 700;
        color: tokens.$form-title-full;
    }

    .firm-view-content {
        font-family: "HelveticaRegular", "Arial", sans-serif;
        font-size: 1rem;
        font-weight: 400;
        color: tokens.$form-title-full;
        overflow-wrap: break-word;
    }
    .divider-firm {
        width: 100%;
        height: 0.02rem;
        background-color: rgba(208, 211, 214, 1);
    }
}
 .employee-form-container {
        .employee-form {
            width: 90%;
            
        }
      .flex-box{
        display: flex;
        flex-direction: row;
      }
    }
.family-edit-container {
    .page-title {
        font-size: 20px;
        font-weight: 700;
    }
    }
@media (max-width: 576px) {
    .superadmin-container {
        .employee-title-page-container {
            padding-left: 1rem !important;
        }        
       
    }
    .employee-view-container {
        .view-details {
            width: 100%;

            .firm-view-second {
                padding-top: 1rem;
            }

            .emp-view-first {
                width: 100%;
            }
        }
    }
    .employee-form-container {
        .employee-form {
            width: 100%;
        }
        .flex-box{
            display: flex;
            flex-direction: column;
            width: 100%;
        }
    }  
}
@media only screen and (max-width: 1024px) and (min-width: 600px) {
    .employee-view-container {
    
        .view-details {
            width: 100%;
        }
    }
} 