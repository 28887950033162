@use "./fonts.scss";
@use "./colors.scss" as tokens;

.wire-transfer {
  .page-title {
    font-weight: 700;
  }

  .btn-new-firm {
    min-width: 180px;
  }
}

.share-button {
  cursor: pointer;
}

#cli-accountNumber {
  input[type="text"]::after {
    content: "X" !important;
    position: absolute;
    left: 0;
    top: 0;
    color: black;
    letter-spacing: 1px; /* Adjust spacing to fit the "X" over each character */
  }
}

.account-number-x {
  -webkit-text-security: disc;
  font-size: 24px !important;
}
