@use "./fonts.scss";
@use "./colors.scss" as tokens;
.offcanvas-change-attorney {
    width: 28% !important;
    .change-attorney-title{
        font-size: 20px;
        font-weight: 400;
        color: tokens.$form-title-full;
      
    }
    .offcanvas-header{
      border: none;
      width: 100%;
      content: "";
      margin: 0 auto;
      border-bottom: 1px solid rgba(193, 201, 210, 1);
      .btn-close{
        padding: 0;
        width: 0.75rem;
        margin-right: .5rem;
      }
   }
   
    .change-attorney-msg{
        font-size: 16px;
        font-weight: 400;
        color: tokens.$form-title-full;
    }  
    .current-attorney-div{
      font-size: 16px;
      font-weight: 400;
      color: tokens.$current-attorney-text;
      overflow: auto;
      .current-attr-text{
        text-wrap: nowrap;
      }
    }
    .current-attorney-div::-webkit-scrollbar-track
    {
        -webkit-box-shadow: inset 0 0 6px rgb(19, 24, 68);
        border-radius: 10px;
        background-color: #F5F5F5;
    }

    .current-attorney-div::-webkit-scrollbar
    {
        height: 8px;
        background-color: #F5F5F5;
    }

    .current-attorney-div::-webkit-scrollbar-thumb
    {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgb(19, 24, 68);
        background-color: #555;
    }
    .new-attorney-title{
      font-size: 14px;
      font-weight: 700;
      color: tokens.$form-title-full;
    }
    .new-attorney-text{
      font-size: 16px;
      font-weight: 400;
      color: tokens.$form-title-full;
      text-wrap: nowrap;    
    }
    .btn-yes{
        font-family: "HelveticaRegular", "Arial", sans-serif;
        background-color: tokens.$form-button-blue;
        padding: 0.313rem 0.75rem 0.25rem 0.75rem;
        border: 1px solid tokens.$form-button-blue;
        &:hover {
            background-color: tokens.$form-button-blue-hover;
          }
          &:active {
            background-color: tokens.$form-button-blue-hover;
            box-shadow: none;
      
            &:focus {
              background-color: tokens.$form-button-blue-hover;
              box-shadow: none;
            }
          }
      
          &:focus {
            background-color: tokens.$form-button-blue-hover;
            box-shadow: none;
          }   
    }
    .btn-no {
        background-color: tokens.$white-color;
        color: tokens.$font-blue-new;
        border: 1px solid tokens.$font-blue-new;
        padding: 0.313rem 0.75rem 0.25rem 0.75rem;
       
        &:hover {
          background-color: tokens.$white-color;
          border: 1px solid tokens.$form-button-blue;
          color: tokens.$font-blue-new;
        }
    
        &:active {
          background-color: tokens.$white-color;
          box-shadow: none;
          color: tokens.$font-blue-new;
    
          &:focus {
            background-color: tokens.$white-color;
            box-shadow: none;
            color: tokens.$font-blue-new;
          }
        }
      }
      .form-check-input {
        border: 2px solid #000000;
    }

    .form-check-input:checked {
        background-color: rgba(235, 88, 22, 255);
        border: rgba(235, 88, 22, 255);
    }  
      .overflow-div{
        //width: 100%;
        overflow-x: auto;
        .attorney-details{
            
            .radio-button-attorney{
                width: 4%;
            }
            .radio-button-details{
                ul {
                    list-style-type: none;
                    
                    li{
                        font-size: 1rem;
                        font-weight: 400;
                        color: tokens.$form-title-full;
                    }
                  }
            }
            .attorney-text{
              font-size: 16px;
              font-weight: 400;
              color: tokens.$form-title-full;
            }
        }
    }
    .overflow-div::-webkit-scrollbar-track
    {
        -webkit-box-shadow: inset 0 0 6px rgb(19, 24, 68);
        border-radius: 10px;
        background-color: #F5F5F5;
    }

    .overflow-div::-webkit-scrollbar
    {
        height: 8px;
        background-color: #F5F5F5;
    }

    .overflow-div::-webkit-scrollbar-thumb
    {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgb(19, 24, 68);
        background-color: #555;
    }
    .radio-erros-msg{
        padding-top: 0 !important;
        color: rgb(204, 0, 0);
        font-size: 0.875rem;
        font-weight: 400;
        text-align: center;
    }  
}

@media (max-width: 576px) {
    .offcanvas-change-attorney {
        width: 78% !important;        
    }
}

@media only screen and (max-width: 1024px) and (min-width: 600px) {
    .offcanvas-change-attorney {
        width: 50% !important;        
    }
}