@use "./colors.scss" as tokens;
.new-folder-modal {
  .custom-form-doc-share {
    .form-input {
      height: 40px;
      font-size: 14px;
      &.phone-height {
        height: 38px;
      }
    }

    .form-text-area {
      border: 0.125rem solid rgb(117, 122, 135);
    }
    .radio-button-container {
      .radio-button-label {
        font-size: 18px;
        font-weight: 400;
      }

      .error-message {
        color: rgb(204, 0, 0);
        font-size: 0.875rem;
        font-weight: 400;
      }
      .new-folder-radio-buttons-wrapper {
        display: flex;
        justify-content: space-around;
        align-items: center;
        #inline-radio-1 {
          height: 1.4rem;
          width: 1.4rem;
          margin-right: 0.25rem;
          content: " ";
          display: inline-block;
          background-color: rgb(26, 133, 19);
          box-shadow: none;
        }
        #inline-radio-1:checked {
          border: 3px solid rgb(159, 188, 170);

          height: 2rem;
          width: 2rem;
          display: block;
          background: rgb(26, 133, 19);
        }
        #inline-radio-2 {
          height: 1.4rem;
          width: 1.4rem;
          margin-right: 0.25rem;
          content: " ";
          display: inline-block;
          background-color: rgb(2, 129, 129);
          box-shadow: none;
        }

        #inline-radio-2:checked {
          border: 3px solid rgb(159, 188, 170);

          height: 2rem;
          width: 2rem;
          content: " ";
          display: block;
          background: rgb(2, 129, 129);
        }
        #inline-radio-3 {
          height: 1.4rem;
          width: 1.4rem;
          margin-right: 0.25rem;
          content: " ";
          display: inline-block;
          background-color: rgb(0, 65, 59);
          box-shadow: none;
        }

        #inline-radio-3:checked {
          border: 3px solid rgb(159, 188, 170);
          height: 2rem;
          width: 2rem;
          content: " ";
          display: block;
          background: rgb(0, 65, 59);
        }
        #inline-radio-4 {
          height: 1.4rem;
          width: 1.4rem;
          margin-right: 0.25rem;
          content: " ";
          display: inline-block;
          background-color: rgb(56, 115, 199);
          box-shadow: none;
        }

        #inline-radio-4:checked {
          border: 3px solid rgb(159, 188, 170);

          height: 2rem;
          width: 2rem;
          content: " ";
          display: block;
          background: rgb(56, 115, 199);
        }
        #inline-radio-5 {
          height: 1.4rem;
          width: 1.4rem;
          margin-right: 0.25rem;
          content: " ";
          display: inline-block;
          background-color: rgb(0, 95, 133);
          box-shadow: none;
        }

        #inline-radio-5:checked {
          border: 3px solid rgb(159, 188, 170);

          height: 2rem;
          width: 2rem;
          content: " ";
          display: block;
          background: rgb(0, 95, 133);
        }
        #inline-radio-6 {
          height: 1.4rem;
          width: 1.4rem;
          margin-right: 0.25rem;
          content: " ";
          display: inline-block;
          background-color: rgb(162, 79, 5);
          box-shadow: none;
        }

        #inline-radio-6:checked {
          border: 3px solid rgb(159, 188, 170);

          height: 2rem;
          width: 2rem;
          content: " ";
          display: block;
          background: rgb(162, 79, 5);
        }
        #inline-radio-7 {
          height: 1.4rem;
          width: 1.4rem;
          margin-right: 0.25rem;
          content: " ";
          display: inline-block;
          background-color: rgb(130, 108, 85);
          box-shadow: none;
        }

        #inline-radio-7:checked {
          border: 3px solid rgb(159, 188, 170);
          height: 2rem;
          width: 2rem;
          content: " ";
          display: block;
          background: rgb(130, 108, 85);
        }
        #inline-radio-8 {
          height: 1.4rem;
          width: 1.4rem;
          margin-right: 0.45rem;
          content: " ";
          display: inline-block;
          background-color: rgb(167, 56, 35);
          box-shadow: none;
        }

        #inline-radio-8:checked {
          border: 3px solid rgb(159, 188, 170);
          height: 2rem;
          width: 2rem;
          content: " ";
          display: block;
          background: rgb(167, 56, 35);
        }
        #inline-radio-9 {
          height: 1.4rem;
          width: 1.4rem;
          margin-right: 0.45rem;
          content: " ";
          display: inline-block;
          background-color: rgb(51, 64, 64);
          box-shadow: none;
        }

        #inline-radio-9:checked {
          border: 3px solid rgb(159, 188, 170);
          height: 2rem;
          width: 2rem;
          content: " ";
          display: block;
          background: rgb(51, 64, 64);
        }
        #inline-radio-10 {
          height: 1.4rem;
          width: 1.4rem;
          margin-right: 0.45rem;
          content: " ";
          display: inline-block;
          background-color: rgb(22, 26, 73);
          box-shadow: none;
        }

        #inline-radio-10:checked {
          border: 3px solid rgb(159, 188, 170);
          height: 2rem;
          width: 2rem;
          content: " ";
          display: block;
          background: rgb(19, 29, 67);
        }
      }
    }
  }

  .modal-content {
    width: 90%;
  }

  .new-folder-modal-body {
    padding: 0px 16px;

    .phone-input-invalid-container {
      .react-tel-input {
        .flag-dropdown {
          background-color: transparent;
          border: 0;
        }
      }
      .react-tel-input .form-control {
        font-size: 1rem;
        border: 2px solid tokens.$form-error-general !important;
        height: 38px !important;
        border-radius: 4px !important;
        color: tokens.$form-color-general;
        width: 100%;
      }
      .react-tel-input {
        .selected-flag {
          background-color: transparent !important;
          border: 0 !important;
        }
      }
      :focus {
        box-shadow: 0 0 0 4px rgb(248, 207, 207);
      }
    }
    .phone-input-valid-container {
      border: 0.125rem solid rgb(117, 122, 135);
      border-radius: 4px;
      .react-tel-input {
        .flag-dropdown {
          background-color: transparent;
          border: 0;
        }
      }
      .react-tel-input .form-control {
        font-size: 1rem;
        height: 38px !important;
        border-radius: 4px !important;
        color: tokens.$form-color-general;
        width: 100%;
      }
      :focus {
        box-shadow: 0 0 0 4px rgba(194, 219, 254, 255);
      }
      .react-tel-input.flag-dropdown {
        background-color: transparent;
        border: 0;
      }
      .react-tel-input.flag-dropdown:hover {
        background-color: transparent;
        border: 0;
      }
    }
    textarea {
      border: 0.125rem solid rgb(117, 122, 135);
    }
    .radio-button-container {
      .radio-button-label {
        font-size: 16px;
        font-weight: 400;
      }
      .radio-buttons-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        //flex-direction: column;
        .form-check-input {
          height: 1.3rem;
          width: 1.3rem;
          border: 2.5px solid rgba(85, 92, 110, 255);
        }

        .form-check-input:checked {
          background-color: rgba(81, 88, 106, 255);
          border: rgba(85, 92, 110, 255);
        }
        .form-check-label {
          padding-top: 0.25rem;
          padding-left: 0.5rem;
        }
      }
      .error-message {
        color: rgb(204, 0, 0);
        font-size: 0.875rem;
        font-weight: 400;
      }
      .rec-files-radio-buttons-wrapper {
        display: flex;
        //justify-content: space-between;
        align-items: center;
        //flex-direction: column;
        .form-check-input {
          height: 1rem;
          width: 1rem;
          border: 2.5px solid rgba(85, 92, 110, 255);
        }

        .form-check-input:checked {
          background-color: rgba(81, 88, 106, 255);
          border: rgba(85, 92, 110, 255);
        }
        .form-check-label {
          padding-top: -0.75rem;
          // padding-left: .5rem;
        }
      }
    }
  }

  .new-folder-modal-footer {
    border-top: 0;
    .new-folder-submit-btn {
      width: 107px;
      height: 34px;
      font-size: 16px;
      font-weight: 400;
      background-color: rgba(22, 26, 73, 1);
      padding-bottom: 1.8rem;
    }
    .new-folder-btn-nobg {
      width: 107px;
      height: 34px;
      font-size: 16px;
      font-weight: 600;
      background-color: white;
      color: rgba(22, 26, 73, 1);
      border: 1px solid rgba(22, 26, 73, 1);
      padding-bottom: 1.8rem;
    }
  }
  .share-modal-width {
    min-width: 50% !important;
  }
}
.new-folder-delete-msg-container {
  .delete-title {
    font-size: 16px !important;
    font-weight: 700 !important;
  }
  .new-folder-delete-text {
    font-size: 16px !important;
    font-weight: 400 !important;
    .folder-name {
      font-weight: 600 !important;
    }
  }
  .yes-button {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .no-button {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

.new-folder-initial-upload {
  .upload-browse-container {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;

    height: 21.625rem;

    border: 1px solid rgb(217, 217, 217, 1);
    border-radius: 8px;
    color: rgb(33, 37, 41);
    transition: border 0.24s ease-in-out;
    .upload-document-text {
      font-size: 1rem;
      font-weight: 700;
    }
  }
}
.new-folder-browse-btn-containter {
  .upload-document-text {
    font-size: 1rem;
    font-weight: 700;
  }
}
.new-folder-document-list {
  //background-color: #ccc !important;
  .custom-card-shadow {
    border-radius: 5px !important;
    .custom-card-body {
      background-color: rgba(250, 250, 250, 1);
    }
  }
  .folder-new-section {
    color: rgba(22, 26, 73, 1);
    font-size: 16px;
    font-weight: 700;
    border-radius: 0;
    border: none;
    cursor: pointer;
  }
  .doc-section {
    .file-name-others {
      width: fit-content;
    }

    .delete-doc-others {
      text-decoration: underline;
      cursor: pointer;

      &.small {
        position: absolute;
        top: 0;
        right: 0;
      }

      &.large {
        position: relative;
        top: -3px;
      }
    }

    .icon-document-web-section {
      & > div {
        svg {
          cursor: pointer;
        }
      }
    }

    .table-custom-document {
      margin: 0;
      font-size: 16px;
      font-weight: 700;
      thead {
        tr {
          td {
            color: rgba(22, 26, 73, 1);
            font-size: 16px;
            font-weight: 700;
            padding: 5px 8px;
          }
        }
      }

      tbody {
        tr {
          vertical-align: bottom;

          td {
            color: rgba(51, 51, 51, 1);
            font-size: 16px;
            font-weight: 400;
            padding: 5px 8px;

            &:last-child {
              vertical-align: bottom;
            }
          }
        }
      }

      tr {
        td {
          border: 0;
        }
      }
    }
    .new-folder-table-td {
      thead {
        tr {
          td {
            padding: 5px 8px;
          }
        }
      }
      tbody {
        tr {
          td {
            padding: 5px 8px;
          }
        }
      }
    }
    .doc-inner-section > div {
      div {
        &:first-child {
          color: rgba(22, 26, 73, 1);
          font-size: 16px;
          font-weight: 700;
        }

        &:last-child {
          color: rgba(51, 51, 51, 1);
          font-size: 16px;
          font-weight: 400;
        }
      }
    }

    .file-part {
      text-decoration: underline;
      cursor: pointer;
      width: fit-content;
    }
  }
  .custom-card-header {
    background-color: rgb(232, 232, 237);
    color: rgb(22, 26, 73);
    font-size: 16px;
    font-weight: 700;
    border-radius: 0;
    border: none;
    cursor: pointer;
    height: 2.5rem;
  }
}

@media (max-width: 576px) {
  .new-folder-modal {
    .modal-content {
      width: 100%;
    }
    .new-folder-modal-body {
      .radio-button-container {
        .form-check {
          margin: 0;
          padding: 0;
        }
        .form-check-inline {
          margin: 0;
          padding: 0;
          padding-left: 1.5rem;
        }
      }
    }
  }
  .new-folder-browse-btn-containter {
    .upload-document-text {
      text-align: center;
    }
  }
}
